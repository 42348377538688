.about__container {
  width: 100%;
  height: 100%;
  position: relative;

  .about__content__wrapper {
    width: 100%;
    height: 100%;
    padding-top: 180px;

    .about__content__infotext {
      text-align: center;
      margin-bottom: 35px;
      margin-right: 160px;
      p {
        font-size: 20px;
        font-weight: 600;
      }
    }

    .flip-card {
      background-color: transparent;
      width: 600px;
      min-height: 600px;
      perspective: 1000px;
      margin: 0 auto;

      .flip-card-inner {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: transform 0.8s;
        transform-style: preserve-3d;
      }
      &.flip-card:hover .flip-card-inner {
        transform: rotateY(180deg);
      }
      .flip-card-front,
      .flip-card-back {
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
      }
      .flip-card-back {
        transform: rotateY(180deg);
        background: #fff;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

        .flip-card-back__text {
          font-size: 16px;
          padding: 40px;
          text-align: left;
          width: 550px;

          p {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 759px) {
  .about__container {
    .about__content__wrapper {
      padding-top: 100px;
      .about__content__infotext {
        margin-left: 10px;
        margin-right: 0;
        text-align: left;
      }
      .flip-card {
        .flip-card-front {
          width: 375px;
          height: 375px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .flip-card-back {
          height: 700px;
          .flip-card-back__text {
            padding: 0;
            width: 340px;
            margin-left: 10px;
          }
        }
      }
    }
  }
}
