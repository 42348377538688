.banner__container {
  width: 100%;
  height: 500px;
  background-image: url('../../assets/img/contact-cover.png');
  position: relative;
  margin-top: 240px;
  .banner__text {
    display: flex;
    justify-content: center;
    padding-top: 115px;
    p {
      font-size: 30px;
      color: white;
      font-weight: 600;
    }
  }
  .banner__btn {
    display: flex;
    justify-content: center;
    margin-top: 130px;

    button {
      border: none;
      outline: none;
      background: white;
      width: 180px;
      height: 50px;
      margin-right: 80px;
      font-weight: 900;
      font-size: 14px;
      text-transform: uppercase;
      transition: 0.4s all ease-in-out;
      cursor: pointer;
    }
    .contact {
      &:hover {
        color: white;
        background-color: transparent;
        border: 2px solid white;
      }
    }
    .services {
      border: 2px solid white;
      background-color: transparent;
      color: white;

      &:hover {
        background-color: white;
        border: none;
        color: black;
      }
    }
  }
}

@media screen and (max-width: 759px) {
  .banner__container {
    margin-top: 130px;
    .banner__text {
      p {
        font-size: 20px;
        margin-left: 25px;
      }
    }
    .banner__btn {
      margin-top: 100px;

      button {
        margin-right: 35px;
        width: 140px;
        height: 50px;
        font-size: 13px;
      }
      .contact {
        margin-left: 34px;
      }
    }
  }
}
