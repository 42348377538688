.footer__container {
  width: 100%;
  margin-top: 124px;
  .main__footer__wrapper {
    width: 100%;
    min-height: 300px;
    background-color: #404040;
    display: flex;
    justify-content: space-evenly;
    padding-top: 54px;

    h4 {
      color: white;
      font-size: 22px;
      margin-bottom: 26px;
    }
    p {
      color: white;
      margin-bottom: 16px;
    }

    a {
      text-decoration: none;
      color: white;

      &:hover {
        text-decoration: underline;
      }
    }

    .main__footer__info {
      h4 {
        color: $main-color;
      }
    }
  }
  .main__footer__links {
    ul {
      li {
        list-style-type: none;
        margin-bottom: 16px;
        position: relative;
        img {
          margin-right: 9px;
          width: 7px;
          height: 9px;
        }
      }
    }
  }
  .main__footer__contact {
    position: relative;
    svg {
      color: $main-color;
      font-size: 20px;
      margin-right: 15px;
      margin-bottom: -4px;
    }
    .email {
      margin-bottom: 16px;
    }
  }
  .secondary__footer {
    width: 100%;
    min-height: 50px;
    background-color: #252525;
    color: #ffffff;
    p {
      opacity: 91%;
      text-align: center;
      line-height: 50px;
      span {
        font-family: 'Arial';
      }
    }
  }
}

@media screen and (max-width: 759px) {
  .footer__container {
    .main__footer__wrapper {
      display: block;
      padding-top: 30px;
      height: 499px;
      padding-left: 15px;
    }
    .secondary__footer {
      font-size: 14px;
    }
  }
}
