.services__container {
  width: 100%;
  position: relative;

  .banner__container {
    margin-top: 180px;
  }

  .services__content__wrapper {
    width: 100%;
    height: 100%;

    .services__content__cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-left: 165px;
      padding-top: 200px;

      .services__content__card {
        width: 360px;
        height: 500px;
        background: #fff;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        margin-right: 150px;
        margin-bottom: 90px;

        .card__text {
          padding-left: 35px;
          padding-top: 45px;
          h2 {
            font-size: 25px;
          }
          p {
            width: 290px;
            margin-top: 10px;
            font-size: 18px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 759px) {
  .services__container {
    .services__content__wrapper {
      .services__content__cards {
        margin-left: 150px;
      }
    }
  }
}
