.home__container {
  width: 100%;
  position: relative;

  .home__content__wrapper {
    padding-top: 194px;
    display: flex;

    .home__content__text {
      margin-left: 101px;
      margin-right: 211px;
      padding-top: 50px;
      width: 743px;

      p {
        font-size: 22px;
        margin-bottom: 69px;

        span {
          font-weight: 600;
        }
      }
      ul {
        li {
          list-style-type: none;
          font-size: 21;
          font-weight: 600;
          margin-bottom: 61px;
          position: relative;
          margin-left: 30px;

          &::before {
            content: '';
            width: 15px;
            height: 15px;
            position: absolute;
            border-radius: 50%;
            top: 5px;
            right: calc(100% + 23px);
            background-color: $main-color;
          }
        }
      }
    }
    .home__content__image {
      width: 720px;
      height: 780px;
      position: relative;
      .image__border {
        border: 2px dashed $main-color;
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 25px;
      }
      .image {
        width: 639px;
        height: 703px;
        margin: 40px auto;

        img {
          border-radius: 25px;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
@media screen and (max-width: 759px) {
  .home__container {
    .home__content__wrapper {
      display: block;
      padding-top: 50px;

      .home__content__text {
        margin-left: 25px;
        width: 330px;
      }
      .home__content__image {
        width: 320px;
        height: 330px;
        margin-left: 27px;

        .image {
          width: 290px;
          height: 300px;

          img {
            margin-top: 15px;
          }
        }
      }
    }
  }
}
