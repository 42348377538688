.contact__container {
  width: 100%;
  position: relative;

  .contact__content__wrapper {
    width: 100%;
    height: 100%;
    padding-left: 565px;
    padding-top: 130px;

    .contact__form {
      form {
        input {
          width: 377px;
          height: 63px;
          border: 2px dashed $main-color;
          border-radius: 10px;
          margin-right: 20px;
          margin-bottom: 20px;
          font-family: 'Kanit', sans-serif;
          color: black;
        }
        textarea {
          width: 774px;
          height: 177px;
          border: 2px dashed $main-color;
          border-radius: 10px;
          resize: none;
          margin-bottom: 37px;
          &::placeholder {
            color: black;
            font-weight: 900;
            padding-left: 25px;
            font-size: 1.2em;
          }
        }

        ::placeholder {
          color: black;
          font-weight: 900;
          padding-left: 25px;
        }

        button {
          border: none;
          outline: none;
          background: white;
          border: 1px solid $main-color;
          width: 180px;
          height: 50px;
          margin-right: 80px;
          font-weight: 900;
          font-size: 14px;
          text-transform: uppercase;
          transition: 0.4s all ease-in-out;
          cursor: pointer;
          color: black;
          z-index: 2;

          &:hover {
            background-color: transparent;
            -webkit-background-color: transparent;
            color: $main-color;
          }
        }
      }
    }
  }
}
.email__message {
  text-align: center;
  font-weight: 600;
  font-size: 25px;
  margin-top: 40px;
}

@media screen and (max-width: 759px) {
  .contact__container {
    .contact__content__wrapper {
      padding-left: 20px;

      .contact__form {
        form {
          input {
            width: 335px;
          }
          textarea {
            width: 335px;
          }
        }
      }
    }
  }
}
