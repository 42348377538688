header {
  width: 100%;
  background-image: url('../../assets/img/hero-image.png');
  min-height: 700px;
  position: relative;
  border-bottom: 5px solid $main-color;

  .navbar {
    display: flex;
    width: 100%;

    svg {
      display: none;
    }

    .logo {
      flex: 1;
      margin-left: 54px;
      margin-top: 38px;
      width: 140px;
      height: 140px;

      img {
        width: 140px;
        height: 140px;
      }
    }

    .navbar__items {
      align-self: center;

      ul {
        list-style-type: none;
        display: flex;

        li {
          a {
            text-decoration: none;
            margin-right: 54px;
            font-size: 25px;
            font-weight: 600;
            color: white;

            &:hover {
              color: $main-color;
              text-decoration: underline;
            }
          }
        }
      }
      .active {
        color: $main-color;
        text-decoration: underline;
      }
    }
  }
  .scroll {
    position: absolute;
    bottom: 42px;
    left: 50%;
  }
}

@media screen and (max-width: 759px) {
  header {
    position: relative;
    z-index: 2;
    min-height: 667px;

    .navbar {
      display: block;

      svg {
        display: block;
        font-size: 25px;
        color: $main-color;
        position: absolute;
        left: 10px;
        top: 10px;
        cursor: pointer;
      }

      .logo {
        margin-top: 0;
        margin-left: 200px;
        padding-top: 18px;
      }

      .navbar__items {
        z-index: 10;
        margin-top: -128px;
        ul {
          display: block;
          width: 320px;
          padding-top: 30px;
          padding-left: 20px;
          height: 335px;

          position: absolute;
          left: -320px;
          transition: 300ms ease all;

          &.active {
            left: 0;
          }

          li {
            height: 30px;
            margin-bottom: 10px;

            .mobile {
              margin-top: 95px;
              display: block;
            }

            &:after {
              display: none;
            }
          }
        }
      }
    }
  }
}
