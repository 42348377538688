* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

a {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

html,
body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: 'Kanit', sans-serif;
  font-weight: 400;
  overflow-x: hidden;
  background-color: #ffffff;
  scroll-behavior: smooth;
}

$main-color: #9ac099;
