.hero__container {
  width: 100%;
  position: absolute;
  top: -400px;
  left: 105px;
  z-index: 2;
  color: white;

  .heading {
    display: flex;
    ::before {
      content: '';
      width: 75px;
      height: 2px;
      position: absolute;
      top: 85px;
      left: 0;
      background-color: $main-color;
    }
    h1 {
      font-size: 50px;
      position: relative;
    }
    h3 {
      font-weight: 400;
      font-size: 40px;
      margin-left: 15px;
      margin-top: 8px;
    }
  }
  h2 {
    font-weight: 400;
    font-size: 35px;
    margin-top: 40px;
  }
  img {
    position: absolute;
    left: 0;
  }
}

@media screen and (max-width: 759px) {
  .hero__container {
    left: 20px;
    top: -420px;

    .heading {
      display: block;
      ::before {
        content: '';
        top: 56px;
      }

      h1 {
        font-size: 30px;
      }
      h2 {
        margin-left: 0;
        font-size: 25px;
      }
    }
    p {
      margin-top: 30px;
      font-size: 23px;
    }
  }
}
